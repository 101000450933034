.background { 
    background-color: #282c34;
    background-image: url("../../assets/images/BIO.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;

}

.pills {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

h2 {
    color: #fff !important;
    font-size: 96px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    text-align: center;
    margin-bottom: 64px;
}