.App {
    text-align: center;
    /* width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

/* #light {
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #000;
  
}

#dark {
    width: 100%;
    height: 100%;
    background-color: #000;
    color: #fff;
} */
