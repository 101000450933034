/* styles.css */
.stockPill {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 50px;
    padding: 8px 16px;
    margin: 8px;
    font-family: Arial, sans-serif;
    color: black;
    box-shadow: 0 0 25px 7.5px #628090;
}

.col {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 125px;
}

.symbol {
    font-weight: bold;
    /* margin-right: 8px; */

}

.price {
    margin-right: auto;
    /* margin-left: auto; */
}

.change {
    padding: 4px 8px;
    border-radius: 12px;
}

.positive {
    color: green;
    /* text-shadow: 1px 1px 1px lightgreen; */
    
}

.negative {
    color: red;
    /* text-shadow: 1px 1px 1px pink; */

}

.logo {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 50%;
}