.GridItem {
    border: 2px solid #ccc;
    overflow: hidden;
    height: 90vh;
    overflow-y: auto;
    /* overflow: hidden; */
}

.chart {
    position: relative;
    /* border: 1px solid #ccc; */

}
