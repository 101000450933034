.bar {
    background-color: transparent;
    padding: 0.5rem;
    display: flex;
    margin-left: 12px;
    margin-right: 12px;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.links {
    display: flex;
    align-items: center;
    gap: 20px;
}

.link {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 300;
    transition: all 0.3s ease;
}