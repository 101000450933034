.react-financial-charts {
    position: absolute !important;
    top: 10;
    left: 0;
    right: 10;
  }
  
  canvas {
    position: absolute !important;
    top: 10;
    left: 0;
  }
  