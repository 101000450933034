.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    border: 1px solid #282c34;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 18px 24px;
    gap: 24px;


}

.field {
    width: 100%;
}