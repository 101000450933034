.tickerTape {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    gap: 100px;
    border: 2px solid #ccc;
    /* background-color: black; */

}

.indexTicker {
    padding: 5px;
    /* border: 1px double #ccc; */
    margin-right: 24px;
    /* border-radius: 0px; */

}

/* .stock-row {

} */