/* ArticleList.css */
.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* background-color: #fff; */
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.articleList {
    list-style: none;
    padding: 0;
    /* border: 1px solid #007bff; */
    border-radius: 5px;
}

.articleItem {
    border: 1px solid #240090;
    padding: 20px;
    /* margin-bottom: 10px; */
}

.articleLink {
    display: block;
    font-size: 16px;
    color: #ccc;
    text-decoration: none;
}

.articleLink:hover {
    text-decoration: underline;
}






/* .newsList {
    display: flex;
    flex-direction: column;
    max-width: 30vw;
    border: 1px solid #888;
    width: 100%;
}

.newsList__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #888;
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    padding: 8px 16px;
}

.newsList__articles {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    /* width: 100%; 
}

.newsList__article {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 0;
}

.newsList__article:not(:last-child) {
    border-bottom: 1px solid #888;
}

span {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

a {
    text-decoration: none;
    color: #fff;
    font-weight: 800;
} */