/* CSS */
.stockRating {
    /* background-color: #f0f0f0; */
    border: 1px solid #ccc;
    /* border-radius: 10px; */
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
}

.ratingInfo {
    margin-top: 10px;
}

.ratingItem {
    margin: 10px 0;
}

h2 {
    color: #333;
}