/* Style for the form container */
.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* background-color: #fff; */
}

/* Style for the form title */
.title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

/* Style for form labels */
.label {
  display: block;
  margin-bottom: 5px;
}

/* Style for form input fields */
.inputField {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Style for the submit button */
.submitButton {
  background-color: gray;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

/* Style for the submit button on hover */
.submitButton:hover {
  background-color: darkgray;
}
