.newsList {
    display: flex;
    flex-direction: column;
    max-width: 30vw;
    border: 1px solid #888;
}

.newsList__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #888;
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    padding: 8px 16px;
}

.newsList__articles {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}

.newsList__article {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 0;
}

.newsList__article:not(:last-child) {
    border-bottom: 1px solid #888;
}

span {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

a {
    text-decoration: none;
    color: #fff;
    font-weight: 800;
}